<template>
    <v-container fluid>
        <v-form @submit.prevent="getClinics('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly && can(['administrator'])" :to="{name: 'clinic.create'}"
                   color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-subheader class="headline">{{ $t('search_clinic') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly && can(['administrator'])" cols="12" sm="4"
                                   class="text-right">
                                <v-btn :to="{name: 'clinic.create'}" color="indigo" dark>
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="clinic" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="clinic" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('user_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="email" rules="email|min:5|max:50"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="email" type="email" :error-messages="errors"
                                                  :disabled="loading"
                                                  prepend-icon="mdi-email" :label="$t('email')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="7">
                                <ValidationProvider ref="company" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="company" :disabled="loadingCompanies"
                                              :items="companyItems" :error-messages="errors"
                                              prepend-icon="mdi-home-variant-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('company')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel"
                                                  v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone" :label="$t('phone')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="filteredHeaders" :items="clinics" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalClinics"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no') }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!can(['administrator'])"
                                       @click="editClinic(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('edit') }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="totalClinics > 1" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted"
                                       @click="deleteClinic(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import {mapGetters, mapActions} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'

export default {
    name: "Clinics",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            fab: false,
            phoneRaw: null,
            clinic: null,
            email: null,
            clinics: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            sortBy: "name",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalClinics: 0,
            options: {},
            loading: false,
            headers: [
                {
                    text: this.$t('user_name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('email'),
                    sortable: false,
                    value: "email"
                },
                {
                    text: this.$t('phone'),
                    sortable: true,
                    value: "phone"
                },
                {
                    text: this.$t('company'),
                    sortable: true,
                    value: "company"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "active",
                    width: 110,
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            loadingCompanies: false,
            company: null,
            companyItems: [],

        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask', 'formAdministrator']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getCompanies()
    },
    watch: {
        options: {
            handler() {
                this.getClinics('pagination')
            },
            deep: false
        }
    },
    methods: {
        ...mapActions(['setFormAdministrator']),
        async getCompanies() {

            this.loadingCompanies = true
            let params = {};
            params.filter = 'search'

            await this.$http
                .get("admin/company", {
                    params: params,
                })
                .then(res => {
                    this.companyItems = res.body.data;
                })
                .catch(err => {
                    this.companyItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_companies'))
                })
                .finally(end => {
                    this.loadingCompanies = false
                })

        },
        editClinic(item) {
            this.$router.push({
                name: 'clinic.edit',
                params: {
                    id: item.id
                }
            })
        },
        async deleteClinic(item) {
            if (confirm(this.$t('delete_clinic'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/clinic/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('clinic_has_been_deleted'))
                        this.getClinics()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('clinic_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getClinics(type) {

            this.loading = true
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc'
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            if (this.clinic) {
                params.clinic = this.clinic
            }
            if (this.phone) {
                params.phone = this.phone
            }
            if (this.email) {
                params.email = this.email
            }
            params.active = this.active

            if (this.company) {
                if (this.company.id) {
                    params.company = this.company.id
                } else {
                    params.company = this.company
                }
            }

            await this.$http
                .get("admin/clinic", {
                    params: params,
                })
                .then(res => {
                    this.clinics = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalClinics = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.clinics = []
                    this.totalClinics = 0
                    this.$toastr.error(this.$t('failed_to_get_list_clinics'))
                })
                .finally(end => {
                    this.loading = false
                })
        }
    }
}
</script>
